import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
   component: () => import( '../views/login.vue')
  },
  {
    path: '/PreviewText',
    name: 'PreviewText',
    component: () => import( '../views/PreviewText.vue')
  },
  {
    path: '/ExerciseLibrary',
    name: 'ExerciseLibrary',
    component: () => import( '../views/ExerciseLibrary.vue')
  },
  {
    path: '/zujian',
    name: 'zujian',
    component: () => import( '../views/zujian.vue')
  },
  {
    path: '/zujian',
    name: 'zujian',
    component: () => import( '../views/zujian.vue')
  },
  {
    path: '/Testlibrary',
    name: 'Testlibrary',
    component: () => import( '../views/Testlibrary.vue')
  },
  {
    path: '/TestContent',
    name: 'TestContent',
    component: () => import( '../views/TestContent.vue')
  },
  {
    path: '/TestingCenter',
    name: 'TestingCenter',
    component: () => import( '../views/TestingCenter.vue')
  },
  {
    path: '/PersonReport',
    name: 'PersonReport',
    component: () => import( '../views/person_report.vue')
  },
  {
    path: '/PersonCenter',
    name: 'PersonCenter',
    component: () => import( '../views/PersonCenter.vue')
  },
  {
    path: '/Intelligence',
    name: 'Intelligence',
    component: () => import( '../views/Intelligence.vue')
  },
 {
   path: '/help',
   name: 'help',
   component: () => import( '../views/help.vue')
 },
 {
   path: '/detail',
  name: 'detail',
   component: () => import( '../views/detail.vue')
 },
 {
   path: '/daochutext',
  name: 'daochutext',
   component: () => import( '../views/daochutext.vue')
 },
 { path: "*", name:'404',
  component: () => import( '../views/404.vue')
  },
  
]

const router = new VueRouter({
  routes
})

export default router
