<template>
	<div class="commonheader">
		<!-- 公共头部 -->

		<div class='top '>
			<div class="centerbox flex just-flex">
				<p>欢迎登录{{infolist.title}}题库、考试平台</p>
				<div class="flex rightbox">

					<router-link to="/help">
						<p style="color:#909399 ;">帮助<i></i></p>
					</router-link>
					<router-link to="/PersonCenter?nowshow=5">
						<p style="color:#909399 ;">消息<i>({{message }})</i></p>
					</router-link>
					<router-link to="/PersonCenter">
						<p style="color:#909399 ;">{{name}}</p>
					</router-link>
					<!-- <router-link to="/login"> -->
					<p style="color:#909399 ;" @click="exit()">退出登录</p>
					<!-- </router-link> -->

				</div>
			</div>
		</div>

		<div class="centertop">
			<div class='centerbox just-flex'>
				<router-link to="/">
					<img :src="infolist.logo" alt="" class="logo">
				</router-link>

				<div class="inputbox flex">
					<el-select v-model="value" placeholder="选择">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
					<input style="min-width: 240px;" type="text" v-model='shitia' placeholder="请输入搜索关键词" class="input1">
					<div class="search flex" @click="sousuo">
						<i class="el-icon-search"></i>
					</div>
				</div>
			</div>
		</div>

		<div class="menu">
			<div class="centerbox flex">


				<el-dropdown style="background-color: #006AD3;">
					<div class="ok">选择当前专业：{{majorname}} <i class="el-icon-arrow-down"></i> </div>
					<el-dropdown-menu slot="dropdown">
						<div class="dropdown">
							<b>专业</b>
							<ul>
								<li v-for="(item,index) in majoroptions" @click="choose(item)"
									:class="{'light':(item.id == major_id)}">
									{{item.title}}
								</li>
							</ul>
							<!-- 	<b style="margin-top: 20px;">专业</b>
							<ul>
								<li v-for="(item,index) in courseoptions">
									{{item.title}}
								</li>
							</ul> -->
						</div>
					</el-dropdown-menu>
				</el-dropdown>
				<!-- <aside>首页</aside> -->
				<router-link to="/">
					<aside :class="{'nowrouter':(title== 'index')}">首页</aside>
				</router-link>
				<router-link to="ExerciseLibrary">
					<aside :class="{'nowrouter':(title== 'ExerciseLibrary')}">习题库</aside>
				</router-link>
				
				<aside @click="Nopermission()" v-if="user.role_id == 3 || user.role_id == 4" :class="{'nowrouter':(title== 'Intelligence')}">智能组卷</aside>
				</aside>
				
				<router-link to="Intelligence" v-else>
					<aside :class="{'nowrouter':(title== 'Intelligence')}">智能组卷</aside>
					</aside>
				</router-link>

				<router-link to="Testlibrary">
					<aside :class="{'nowrouter':(title== 'Testlibrary')}">试卷库</aside>
				</router-link>
				
				<aside @click="Nopermission()" v-if="user.role_id == 3 || user.role_id == 4"  :class="{'nowrouter':(title== 'TestingCenter')}">考试中心</aside>
				</aside>
				
				
				<router-link to="TestingCenter" v-else>
					<aside :class="{'nowrouter':(title== 'TestingCenter')}">考试中心</aside>
				</router-link>
				<router-link to="help">
					<aside :class="{'nowrouter':(title== 'help')}">帮助中心</aside>
				</router-link>

			</div>
		</div>



	</div>
</template>

<script>
	import {

		getmajor,
		getcourse,
		getmodule,
		userInfo,
		schoolData,
		
	} from '@/api/api'
	import {
		setToken,
		getToken,
		removeToken
	} from '@/utils/setToken'
	export default {
		props: {
			title: {
				type: String,
				    default: ''
			}
		},
		data() {
			return {
				options: [{
					value: '1',
					label: '试题'
				}, {
					value: '2',
					label: '试卷'
				}],
				value: '',
				majoroptions: [],
				major_id: '',
				majorname: '',
				courseoptions: [],
				message: '',
				shitia: '',
				name :'',
				infolist:{},
				user:{},
				titleline:''

			}
		},
		created() {
			this.titleline = this.title
			console.log(this.titleline)
		},
		methods: {
			
			Nopermission(){
				this.$message({
					message:'当前身份无权限',
					type:'warning'
				})
			},
			schoolData() {
				var id = getToken('school_id')
				if (!id) {
					this.$router.push('/login');
				} else {
					schoolData({
						id: getToken('school_id')
					}).then(res => {
						console.log(res.data.data)
						this.infolist = res.data.data
						console.log(this.infolist.ip, this.nowip)
						if ((this.infolist.ip != this.nowip) && id != 0) {
							if(this.infolist.ip == ''){
								this.showip = true
							}else{
								this.$message({
									message: '无效的登录IP',
									type: 'warning'
								})
								this.$router.push('/login');
							}
							
						} else {
							this.showip = true
						}
					})
				}
				schoolData({
					id:getToken('school_id')
				}).then(res => {
					console.log(res.data.data)
					this.infolist = res.data.data
					
				})
			},
			
			exit() {
				this.$alert('是否退出登录', '提示', {
					confirmButtonText: '确定',
					showCancelButton: true,
					callback: action => {
						if (action === 'confirm') {
							removeToken('token')
							this.$router.push('/login');
						} else {
							// done();
						}

					}
				});

			},
			// chooseKm(){
			// 	console.log(1)
			// },
			sousuo() {
				console.log(this.titleline)
				if(this.titleline !='ExerciseLibrary'){
					if (this.value == 1) {
						this.$router.push('/ExerciseLibrary?note=' + this.shitia);
					} else {
						this.$router.push('/ExerciseLibrary?title=' + this.shitia);
					}
				}else{
					this.$emit('searchbox',this.shitia)
				}
				

			},
			gettop() {
				this.major_id = getToken('major_id')
				getmajor().then(res => {

					this.majoroptions = res.data.data.data
					var Tokenmajor = getToken('major_id')
					this.majorname = getToken('majorname')
					console.log(this.major_id)
					if (this.major_id == 'undefined' || this.major_id == null || !this.major_id) {
						this.major_id = this.majoroptions[0].id
						this.majorname = this.majoroptions[0].title
						// console.log(this.majoroptions[0].title)
						setToken('major_id', this.major_id)
						setToken('majorname', this.majorname)
					}
					this.getc()

				})
			},
			choose(item) {
				setToken('major_id', item.id)
				setToken('majorname', item.title)
				this.major_id = item.id
				this.majorname = item.title
				this.getc()
				location.reload();
			},
			getc() {
				getcourse({
					major_id: this.major_id
				}).then(res => {
					this.courseoptions = res.data.data.data
				})
			},
			getinfo() {
				userInfo().then(res => {
					this.name = res.data.data.name
					this.message = res.data.data.message
					this.user = res.data.data
				})
			},
			handleClose(done) {
				console.log(done)
			}
		},
		mounted() {
			if (this.$route.query.school_id) {
				setToken('school_id', this.$route.query.school_id)
				removeToken('token')
			} else {
			
				// setToken('school_id', 1)
			}
			this.schoolData()
			this.gettop()
			this.getinfo()
			
		},

	}
</script>

<style>
	.commonheader {
		background-color: #fff;
	}

	.commonheader .top {
		width: 100%;
		background: #F4F4F5;
		height: 40px;
		color: #909399;
		min-width: 1200px;
	}

	.commonheader .top .centerbox {
		height: 100%;
	}

	.commonheader .rightbox p {
		    padding: 0 18px;
		border-right: 1px solid #E3E3E6;
	}

	.commonheader .rightbox p:last-child {
		border: 0;
	}

	.commonheader .rightbox i {
		color: #F56C6C;
	}

	.centertop {
		padding: 32px 0 27px;
	}

	.commonheader .centertop .logo {
		width: 242px;
		height: 44px;
	}

	.commonheader input {
		border: none;
		width: 90px;
		outline: none;
	}

	input::-webkit-input-placeholder {
		/* WebKit browsers */

		color: #C0C4CC;

	}

	input:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */

		color: #C0C4CC;

	}

	input::-moz-placeholder {
		/* Mozilla Firefox 19+ */

		color: #C0C4CC;

	}

	input:-ms-input-placeholder {
		/* Internet Explorer 10+ */

		color: #C0C4CC;

	}

	.input1 {
		height: 40px;
		flex: 1;
		width: 240px;
	}

	.inputbox {
		width: 401px;
		height: 42px;
		background: #FFFFFF;
		border: 1px solid #409EFF;
		border-radius: 3px;
	}

	.search {
		width: 62px;
		height: 42px;
		background: #409EFF;
		border-radius: 0px 2px 2px 0px;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-size: 22px;
	}

	.menu {
		width: 100%;
		height: 50px;
		background: #409EFF;
		min-width: 1200px;

	}

	.menu .centerbox {
		height: 50px;
	}

	.menu aside {
		flex: 1;
		text-align: center;
		color: #fff;
		height: 100%;
		line-height: 50px;
		cursor: pointer;
	}

	.menu a {
		flex: 1;
		text-align: center;
		color: #fff;
		height: 100%;
		line-height: 50px;
		cursor: pointer;
	}

	.commonheader .menu .ok {
		width: 300px;
		text-align: center;
		color: #fff;
		height: 100%;
		line-height: 50px;
		cursor: pointer;
	}

	.menu aside:hover {
		background: #3C97F1;
	}

	.menu .ok:hover {
		background: #3C97F1;
	}

	.nowrouter {
		background: #3C97F1;
	}

	.ok i {
		margin-left: 47px;
	}

	.dropdown {
		width: 300px;
		padding: 15px 15px;
		box-sizing: border-box;
	}

	.dropdown b {
		font-weight: 600;
		/* padding: 0px 10px; */
		display: block;
		margin-bottom: 10px;


	}

	.dropdown ul {
		display: flex;
		flex-wrap: wrap;
		/* margin: 10px 0; */
	}

	.dropdown li {
		/* padding: 2px;
		margin: 2px; */
		/* display: block; */
		margin-bottom: 10px;
		margin-right: 10px;
		color: #9F9F9F;
	}

	.dropdown .light {
		color: #006AD3;
	}

	.commonheader .el-select {
		width: 100px;
	}

	.commonheader .el-input {
		width: 100px;
	}
</style>